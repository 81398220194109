
$speed: 0.5s;

.btn-flip{
  opacity: 1;
  outline: 0;
  color: white;
  line-height: 40px;
  position: relative;
  text-align: center;
  letter-spacing: 1px;
  display: inline-block;
  text-decoration: none;
  font-family: 'Open Sans';
  text-transform: uppercase;
  
  &:hover{
    
    &:after{
      opacity: 1;
      transform: translateY(0) rotateX(0);
    }
    
    &:before{
      opacity: 0;
      transform: translateY(50%) rotateX(90deg);
    }
  }
  
  &:after{
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    color: white;
    display: block;
    transition: $speed;
    position: absolute;
    background: #adadaf;
    content: attr(data-back);
    transform: translateY(-50%) rotateX(90deg);
  }
  
  &:before{
    top: 0;
    left: 0;
    opacity: 1;
    color: white;
    display: block;
    padding: 0 30px;
    line-height: 40px;
    transition: $speed;
    position: relative;
    background: #323237;
    content: attr(data-front);
    transform: translateY(0) rotateX(0);
    border-radius: 5px;
  }
}