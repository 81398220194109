@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap');

.header-button {
  z-index: 1;
 color: white;


}
.textcolor{color: rgb(62, 62, 254);}
.bgcolor {  background: rgb(62, 62, 254);}
/* Aylen */
.header-button.button--aylen {
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.button--aylen::before,
.button--aylen::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 100%;
  left: 0;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--aylen::before {
  background: rgb(62, 62, 254);
}
.button--aylen::after {
  background: black;
}
.button--aylen:hover::before,
.button--aylen:hover::after {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.button--aylen:hover::after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

/*  ================================= */



.swiper-slide {
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-vertical.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 13px) 0 !important;
  width: 12px;
  height: 12px;
  display: block;
  background: black;
}

.swiper-vertical > .swiper-pagination-bullets,
.swiper-pagination-vertical.swiper-pagination-bullets {
  right: var(--swiper-pagination-right, 8px);
  left: var(--swiper-pagination-left, auto);
  top: 50%;
  transform: translate3d(0px, -50%, 0);
  opacity: 1;
  padding: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
}

/* 
.swiper-button-prev:after,
.swiper-button-next:after {

  font-size: var(--swiper-navigation-size);
  text-transform: none !important;
  letter-spacing: 0;
  line-height: 1;
  background: white;
} */
.is-sticky {
  position: sticky;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);

  backdrop-filter: blur(10px);
  animation: slideDown 0.35s ease-in-out;
  top: 0;
  z-index: 50;
  background: white;
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.slider-content-wrapper .slider-content .slider-btn-wrapper {
  margin-bottom: 40px;
  justify-content: center;
}
/* ========================= */
/* End of the Header */
/* ========================= */

/**
 * Stylesheet:  style.css
 * Theme:       Technova - IT Startup & Agency HTML5 Template
 * Version:     1.0
 * Author:     	BoomDevs
 * 
 */
/* Table of contents
=========================
1.Preloader
2.Scrollup
3.Nav Menu
4.Slider Section
5.Info Card
6.Icon Card
7.About Image Card
8.About Info Card
9.Counter Card
10.Process Step Card
11.Testimonial Card
12.Accordion Card
13.Client Logo Card
14.Post Card
15.Skill Progress Card
16.Price Card
17.Call To Action Card
18.Title Card
19.Breadcrumb
20.Pagination's
21.Project Details Page
22.Sidebar
23.Comment List
24.Comment Form
25.Team Member
26.Portfolio
27.About Page
28.Contact Page
29.Service Page
30.Team Page
Footer


===========================*/
/* Theme Default CSS */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .info-card.style-1 .content {
    gap: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .info-card.style-1 .content {
    gap: 30px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .info-card.style-1 .content {
    gap: 30px;
  }
}
.info-card.style-1 .content .desc {
  color: rgba(255, 255, 255, 0.7176470588);
}
.info-card.style-1 .content .read-more a {
  color: #ffffff;
}
.info-card.style-1 .content .read-more a:hover {
  color: #d9a14c;
}
.info-card.style-1 .read-more {
  display: flex;
  justify-content: start;
}
.info-card.style-1 .read-more a {
  font-family: "Rajdhani", sans-serif;
  font-size: 16px;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  line-height: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
}
.info-card.style-1 .read-more a:hover i {
  color: rgb(41, 41, 254);
  transition: all 0.5s ease-out;
}
.info-card:hover .image-wrapper .overlay {
  transition: all 0.5s ease-out;
  opacity: 0.72;
}
.info-card:hover .image-wrapper img {
  transform: scale(1.2);
  transition: all 0.5s ease-out;
}
.info-card:hover .icon img {
  transform: rotateY(360deg);
  transition: all 0.5s ease-out;
}
.info-card .info-card-inner {
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
}
.info-card .divider:before {
  content: "";
  position: absolute;
  background-color: rgb(94, 111, 146);
  opacity: 0.2;
  right: 0;
  height: 80%;
  width: 1px;
  top: 45px;
}
.info-card .image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  top: 0;
  left: 0;
  transition: all 0.6s ease;
}
.info-card .image-wrapper img {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: all 0.5s ease-out;
}
.info-card .image-wrapper .overlay {
  width: 100%;
  height: 100%;
  background: rgb(41, 41, 254);
  transition: all 0.5s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
}
.info-card .icon-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.info-card .icon {
  display: flex;
  flex-shrink: 0;
  justify-content: start;
  width: 54px;
  height: 50px;
}
.info-card .icon img {
  transition: all 0.5s ease-out;
}
.info-card .content-wrapper {
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.info-card .title-wrapper {
  display: flex;
  gap: 27px;
  padding-bottom: 15px;
  border-bottom: none;
  transition: all 0.6s ease;
  flex-direction: column;
  align-items: start;
}
.info-card .title-wrapper .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 74px;
  height: 74px;
  background: transparent;
}
@media only screen and (min-width: 0px) and (max-width: 576px) {
  .info-card .title-wrapper .icon {
    width: auto;
    height: auto;
  }
}
.info-card .title-wrapper .title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  margin: 0;
  color: #26262c;
  transition: all 0.6s ease;
}
.info-card .content {
  display: flex;
  flex-direction: column;
  gap: 43px;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .info-card .content {
    gap: 24px;
  }
}
.info-card .content .desc {
  transition: all 0.6s ease;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  margin: 0;
  color: #616670;
}

.info-card.style-2 {
  padding: 40px;
  border: 3px solid #dedede;
  transition: all 0.5s ease-out;
  top: 0;
  position: relative;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .info-card.style-2 {
    padding: 30px 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .info-card.style-2 {
    padding: 40px 30px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .info-card.style-2 {
    padding: 30px 24px;
  }
}
.info-card.style-2:hover {
  transition: all 0.5s ease-out;
  border-color: transparent;
  background-color: #ffffff;
  box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.05);
  top: -5px;
}
.info-card.style-2 .title-wrapper {
  gap: 15px;
  padding-bottom: 20px;
}
.info-card.style-2 .title-wrapper .icon-wrapper {
  padding-right: 6px;
  margin-top: -10px;
}
.info-card.style-2 .title-wrapper .icon {
  width: 65px;
  height: 65px;
}
.info-card.style-2 .counter-number-wrapper .counter-number {
  font-size: 68px;
  font-weight: 700;
  line-height: 78.2px;
  color: #ffffff;
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  opacity: 0.3;
}
.info-card.style-2 .content {
  gap: 20px;
}
.info-card.style-2 .content .read-more {
  display: flex;
  justify-content: space-between;
}
.info-card.style-2 .content .read-more a {
  font-family: "Rajdhani", sans-serif;
  font-size: 16px;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  line-height: 32px;
  font-weight: 700;
  gap: 10px;
  color: #23262d;
  display: flex;
  align-items: center;
}
.info-card.style-2 .content .read-more a:hover i {
  color: #d9a14c;
  transition: all 0.5s ease-out;
}

.info-card.style-3 {
  padding: 0 40px 40px 40px;
  border: 1px solid #e3e3e3;
}
@media (max-width: 1399px) {
  .info-card.style-3 {
    padding: 24px;
    padding-top: 0;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .info-card.style-3 {
    padding-bottom: 30px;
  }
}
.info-card.style-3 .title-wrapper {
  align-items: center;
  padding-bottom: 20px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .info-card.style-3 .title-wrapper {
    padding-bottom: 16px;
  }
}
.info-card.style-3 .title-wrapper .icon {
  background-color: #d9a14c;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
  padding: 8px;
  flex-shrink: 0;
}
@media only screen and (min-width: 0px) and (max-width: 576px) {
  .info-card.style-3 .title-wrapper .icon {
    width: 70px;
    height: 70px;
  }
}
.info-card.style-3 .title-wrapper .counter-number {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  color: #23262d;
}
.info-card.style-3 .content {
  text-align: center;
  justify-content: center;
}

.info-card.style-4 {
  padding: 40px 40px 30px 40px;
  background-color: #ffffff;
  position: relative;
  top: 0;
  transition: all 0.5s ease-out;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .info-card.style-4 {
    padding: 30px 30px 20px 30px;
  }
}
.info-card.style-4:hover {
  top: -5px;
  transition: all 0.5s ease-out;
}
.info-card.style-4:hover .content .read-more a {
  color: #ffffff;
  background-color: #d9a14c;
  transition: all 0.5s ease-out;
}
.info-card.style-4:hover .content .read-more a i {
  color: #ffffff;
}
.info-card.style-4 .title-wrapper {
  padding-bottom: 20px;
}
.info-card.style-4 .content {
  gap: 40px;
}
.info-card.style-4 .content .read-more {
  display: flex;
  gap: 96px;
  align-items: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .info-card.style-4 .content .read-more {
    justify-content: space-between;
    gap: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .info-card.style-4 .content .read-more {
    justify-content: space-between;
    gap: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .info-card.style-4 .content .read-more {
    justify-content: space-between;
    gap: 0;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .info-card.style-4 .content .read-more {
    justify-content: space-between;
    gap: 0;
  }
}
.info-card.style-4 .content .read-more a {
  white-space: nowrap;
  padding: 20px 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Rajdhani", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  color: #23262d;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .info-card.style-4 .content .read-more a {
    padding: 10px 20px;
    font-size: 13px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .info-card.style-4 .content .read-more a {
    padding: 10px 20px;
    font-size: 13px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .info-card.style-4 .content .read-more a {
    padding: 10px 20px;
    font-size: 13px;
  }
}
.info-card.style-4 .content .read-more a i {
  color: #d9a14c;
  font-size: 14px;
}
.info-card.style-4 .content .read-more .counter-number {
  font-family: "Rajdhani", sans-serif;
  font-size: 68px;
  font-weight: 700;
  line-height: 78.2px;
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  opacity: 0.05;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .info-card.style-4 .content .read-more .counter-number {
    font-size: 60px;
    line-height: normal;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .info-card.style-4 .content .read-more .counter-number {
    font-size: 60px;
    line-height: normal;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .info-card.style-4 .content .read-more .counter-number {
    font-size: 60px;
    line-height: normal;
  }
}

.info-card.style-5 {
  padding: 0 40px 40px 40px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
}
@media only screen and (min-width: 0px) and (max-width: 576px) {
  .info-card.style-5 .content .desc {
    font-size: 14px;
  }
}
.info-card.style-5 .title-wrapper {
  padding-bottom: 20px;
}
@media only screen and (min-width: 0px) and (max-width: 576px) {
  .info-card.style-5 .title-wrapper .title {
    font-size: 22px;
  }
}
.info-card.style-5 .title-wrapper .icon {
  background-color: #d9a14c;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
  padding: 8px;
  flex-shrink: 0;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .info-card.style-5 .title-wrapper .icon {
    width: 60px;
    height: 60px;
  }
}
.info-card.style-5 .title-wrapper .counter-number {
  font-family: "Rajdhani", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  color: #23262d;
  -webkit-text-stroke: 2px black;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  opacity: 0.1;
}

.feature-area.style-1 .section-title {
  margin-bottom: 84px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .feature-area.style-1 .section-title {
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-area.style-1 .section-title {
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-area.style-1 .section-title {
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .feature-area.style-1 .section-title {
    margin-bottom: 40px;
  }
}
.feature-area.style-1 .section-title .short-title-wrapper {
  margin-top: -7px;
}
.feature-area.style-1 .section-title .title {
  margin-bottom: 10px;
  text-align: center;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section-title {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .section-title {
    flex-direction: column;
    margin-bottom: 30px;
    align-items: flex-start;
    gap: 40px;
  }
}
.section-title .sec-content {
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: relative;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .section-title .sec-content {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title .sec-content .sec-desc p br {
    display: none;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .section-title .sec-content .sec-desc p br {
    display: none;
  }
}
.section-title .short-title {
  font-family: "Rajdhani", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #d9a14c;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.section-title .short-title::before,
.section-title .short-title::after {
  content: "";
  width: 20px;
  height: 1px;
  border-bottom: 1px solid #d9a14c;
  flex: 1 1;
}
.section-title .short-title.no-divider:before,
.section-title .short-title.no-divider:after {
  display: none;
}
.section-title .short-title.only-divider::before {
  display: none;
}
.section-title .title {
  font-family: "Rajdhani", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  color: #23262d;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title .title {
    font-size: 48px;
    line-height: 62px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .section-title .title {
    font-size: 47px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title .title {
    font-size: 38px;
    line-height: 48px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .section-title .title {
    font-size: 32px;
    line-height: 42px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 439px) {
  .section-title .title br {
    display: none;
  }
}
.section-title .title span {
  color: #d9a14c;
}
.section-title .sec-desc {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #616670;
  margin-bottom: 0;
  flex-shrink: 0;
}
.section-title .sec-desc .theme-btn {
  background-color: transparent;
  color: #23262d;
  flex-shrink: 0;
}
.section-title .sec-desc .theme-btn:hover {
  background-color: #d9a14c;
  color: #ffffff;
  transition: all 0.5s ease-out;
}
.section-title.two-column {
  align-items: flex-end;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .section-title.two-column {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.two-column {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .section-title.two-column .sec-content {
    width: 100%;
  }
}
.section-title.two-column .sec-desc {
  width: 537px;
  font-family: "Rubik", sans-serif;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title.two-column .sec-desc {
    width: 440px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.two-column .sec-desc {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .section-title.two-column .sec-desc {
    width: 100%;
  }
}
.section-title.two-column .sec-desc .desc {
  margin-bottom: 8px;
}
.section-title.two-column .sec-desc .simple-btn {
  font-size: 18px;
  font-weight: 500;
  font-family: "Rajdhani", sans-serif;
  color: #23262d;
  display: flex;
  align-items: center;
  gap: 20px;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 60px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section-title {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .section-title {
    flex-direction: column;
    margin-bottom: 30px;
    align-items: flex-start;
    gap: 40px;
  }
}
.section-title .sec-content {
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: relative;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .section-title .sec-content {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title .sec-content .sec-desc p br {
    display: none;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .section-title .sec-content .sec-desc p br {
    display: none;
  }
}
.section-title .short-title {
  font-family: "Rajdhani", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #d9a14c;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.section-title .short-title::before,
.section-title .short-title::after {
  content: "";
  width: 20px;
  height: 1px;
  border-bottom: 1px solid #d9a14c;
  flex: 1 1;
}
.section-title .short-title.no-divider:before,
.section-title .short-title.no-divider:after {
  display: none;
}
.section-title .short-title.only-divider::before {
  display: none;
}
.section-title .title {
  font-family: "Rajdhani", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  color: #23262d;
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title .title {
    font-size: 48px;
    line-height: 62px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .section-title .title {
    font-size: 47px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title .title {
    font-size: 38px;
    line-height: 48px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .section-title .title {
    font-size: 32px;
    line-height: 42px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 500px) {
  .section-title .title br {
    display: none;
  
  }
  .section-title .title  {
font-size: 20px;
  
  }
  

}
.section-title .title span {
  color: #d9a14c;
}
.section-title .sec-desc {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #616670;
  margin-bottom: 0;
  flex-shrink: 0;
}
.section-title .sec-desc .theme-btn {
  background-color: transparent;
  color: #23262d;
  flex-shrink: 0;
}
.section-title .sec-desc .theme-btn:hover {
  background-color: #d9a14c;
  color: #ffffff;
  transition: all 0.5s ease-out;
}
.section-title.two-column {
  align-items: flex-end;
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .section-title.two-column {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.two-column {
    flex-direction: column;
    gap: 30px;
    margin-bottom: 50px;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .section-title.two-column .sec-content {
    width: 100%;
  }
}
.section-title.two-column .sec-desc {
  width: 537px;
  font-family: "Rubik", sans-serif;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title.two-column .sec-desc {
    width: 440px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title.two-column .sec-desc {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .section-title.two-column .sec-desc {
    width: 100%;
  }
}
.section-title.two-column .sec-desc .desc {
  margin-bottom: 8px;
}
.section-title.two-column .sec-desc .simple-btn {
  font-size: 18px;
  font-weight: 500;
  font-family: "Rajdhani", sans-serif;
  color: #23262d;
  display: flex;
  align-items: center;
  gap: 20px;
}

.feature-area.style-2 .section-title {
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0;
}
.feature-area.style-3 {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-area.style-3 {
    padding: 100px 0;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .feature-area.style-3 {
    padding: 80px 0;
  }
}
.feature-area.style-3 .feature-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.feature-area.style-3 .section-title {
  margin-bottom: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-area.style-3 .section-title {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .feature-area.style-3 .section-title {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .feature-area.style-3 .section-title.two-column {
    gap: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-area.style-3 .section-title.two-column {
    gap: 10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-area.style-3 .section-title.two-column {
    gap: 10px;
  }
}
.feature-area.style-3 .section-title .title {
  margin-bottom: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-area.style-3 .section-title .title {
    font-size: 42px;
  }
}
.feature-area.style-3 .section-title .sec-desc {
  margin-right: -3px;
}
@media (min-width: 1400px) {
  .feature-area.style-3 .section-title .sec-desc {
    width: 525px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-area.style-3 .section-title .sec-desc {
    margin-bottom: 0;
    width: 420px;
  }
}

.section-subtitle {
  display: inline-block;
  font-weight: 500;
  font-size: 1.142rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #cee002;
  margin-bottom: 1rem;
}
.section-about-title {
  margin-bottom: 5rem;
}
.section-about p {
  text-align: justify;
}
.about-section-home-htwo {
  font-weight: 600;
  font-size: 2.571rem;
  line-height: 1.166;
  color: #000;
  letter-spacing: 0;
}
.experience-box {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  margin: 6rem 0;
}
@media (min-width: 992px) {
  .experience-box {
    margin-top: 6rem;
  }
}
.experience-border {
  display: inline-block;
  vertical-align: middle;
  width: 12.15rem;
  height: 12.15rem;
  border: 0.357rem solid #cee002;
}
.experience-content {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  left: -8rem;
  top: 0.2rem;
}
.experience-number {
  display: inline-block;
  vertical-align: middle;
  font-size: 7.142rem;
  line-height: 1;
  font-weight: 600;
  color: #000;
}
.experience-info {
  display: inline-block;
  vertical-align: middle;
  margin: -1rem 0 0 0.8rem;
  font-size: 1.57rem;
  line-height: 1.181;
  color: #000;
}

.dots-image {
  position: relative;
}
.dots-image img {
  position: relative;
  z-index: 1;
}
.dots-image .dots {
  position: absolute;
  left: -20%;
  bottom: -10%;
  width: 101%;
  height: 57.2%;
  background: url(../images//section-bg/dots.png) 0 0 repeat;
}

/* =========================== */
/* Cta section */
/* ============================ */
.cta-area {
  margin-top: 5%;
  margin-bottom: 5px;
  padding: 50px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
  background-image: url(../images/section-bg/cta-section-bg-one.jpg);
}
.cta-area .cta-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(35, 38, 45, 0.8);
}
.cta-area .cta-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.cta-area.style-2 .section-title {
  margin-bottom: 20px;
}

.cta-area .section-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  gap: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title {
    margin-bottom: 20px;
  }
}
.cta-area .section-title .short-title {
  margin-bottom: 0;
}
.section-title .short-title {
  font-family: "Rajdhani", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #d9a14c;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.cta-area .section-title .title {
  color: #ffffff;
}
.cta-area.style-2 .btn-wrapper {
  margin-left: -10px;
}
.theme-btn,
.wp-block-loginout a,
input.theme-btn {
  display: inline-flex;
  background-color: #d9a14c;
  border: 1px solid #d9a14c;
  color: #ffffff;
  padding: 14px 24px;
  text-align: center;
  font-weight: 700;
  font-family: "Rajdhani", sans-serif;
  text-transform: uppercase;
  position: relative;
  transition: all 0.5s ease-out;
  font-size: 16px;
  letter-spacing: 1.28px;
  align-items: center;
  gap: 15px;
  overflow: hidden;
  z-index: 2;
  white-space: nowrap;
}
.fa-solid,
.fass {
  font-weight: 900;
}
/* ============================== testimonial  */

.testimonials-container {
  padding: 0 40px;
  margin: 30px 0 50px;
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
}

.testimonials {
  display: flex;
  justify-content: space-evenly;
  align-items: space-evenly;
  background: linear-gradient(
    to right bottom,
    #fabf8f60,
    #ffc0a660,
    #ffc4bc60,
    #ffcbcf60,
    #f7d3dd60
  );
}

.testimonials-container h2 {
  padding: 10px 10px 15px;
  text-align: center;
  width: 100%;
  margin: 0 auto 30px;
}

.testimonial {
  width: 100%;
  border-radius: 20px;
  background: linear-gradient(
    to right bottom,
    #fabf8f60,
    #ffc0a660,
    #ffc4bc60,
    #ffcbcf60,
    #f7d3dd60
  );
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}

.testimonial:after {
  content: "";
  display: block; /* reduce the damage in FF3.0 */
  position: absolute;
  bottom: -15px;
  right: 50px;
  width: 0;
  border-width: 15px 20px 0;
  border-style: solid;
  border-color: #f7d3dd60 transparent;
}

.testimonial h3 {
  margin-bottom: 10px;
}

.testimonial p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.testimonal-author {
  font-size: 1rem;
  position: absolute;
  bottom: -40px;
  right: 0;
}

@media all AND (max-width: 700px) {
  .testimonials {
    flex-direction: column;
    align-items: center;
  }

  .testimonial {
    width: 90%;
    margin-bottom: 60px;
  }
}
/* ============= */
.bg-dots {
  background: url(../images//section-bg/dots.png) 0 0 repeat;
}
.section-clients .section-title {
  margin-right: 7rem;
}
.swiper-testi {
  background: transparent;
  height: fit-content;
  margin-bottom: 2%;
}
.one-slider img {
  display: block;
  width: unset !important;
  height: unset !important;
}


.heading {
  color: hsla(0, 0%, 0%, .9);
  font: normal  Varela Round, sans-serif;
  height: fit-content;
  left: 0;
  letter-spacing: 5px;


  text-align: center;
  text-transform: uppercase;

  width: 100%;
  animation: move linear 2000ms infinite;  
}

@keyframes move {
  0% {
    text-shadow:
      4px -4px 0 hsla(0, 100%, 50%, 1), 
      3px -3px 0 hsla(0, 100%, 50%, 1), 
      2px -2px 0 hsla(0, 100%, 50%, 1), 
      1px -1px 0 hsla(0, 100%, 50%, 1),
      -4px 4px 0 hsla(180, 100%, 50%, 1), 
      -3px 3px 0 hsla(180, 100%, 50%, 1), 
      -2px 2px 0 hsla(180, 100%, 50%, 1), 
      -1px 1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
  25% {    
    text-shadow:      
      -4px -4px 0 hsla(180, 100%, 50%, 1), 
      -3px -3px 0 hsla(180, 100%, 50%, 1), 
      -2px -2px 0 hsla(180, 100%, 50%, 1), 
      -1px -1px 0 hsla(180, 100%, 50%, 1),
      4px 4px 0 hsla(0, 100%, 50%, 1), 
      3px 3px 0 hsla(0, 100%, 50%, 1), 
      2px 2px 0 hsla(0, 100%, 50%, 1), 
      1px 1px 0 hsla(0, 100%, 50%, 1)      
    ;
  }
  50% {
    text-shadow:
      -4px 4px 0 hsla(0, 100%, 50%, 1), 
      -3px 3px 0 hsla(0, 100%, 50%, 1), 
      -2px 2px 0 hsla(0, 100%, 50%, 1), 
      -1px 1px 0 hsla(0, 100%, 50%, 1),
      4px -4px 0 hsla(180, 100%, 50%, 1), 
      3px -3px 0 hsla(180, 100%, 50%, 1), 
      2px -2px 0 hsla(180, 100%, 50%, 1), 
      1px -1px 0 hsla(180, 100%, 50%, 1)
    ;
  }
  75% {
    text-shadow:
      4px 4px 0 hsla(180, 100%, 50%, 1), 
      3px 3px 0 hsla(180, 100%, 50%, 1), 
      2px 2px 0 hsla(180, 100%, 50%, 1), 
      1px 1px 0 hsla(180, 100%, 50%, 1),
      -4px -4px 0 hsla(0, 100%, 50%, 1), 
      -3px -3px 0 hsla(0, 100%, 50%, 1), 
      -2px -2px 0 hsla(0, 100%, 50%, 1), 
      -1px -1px 0 hsla(0, 100%, 50%, 1)      
    ;
  }
  100% {
    text-shadow:
      4px -4px 0 hsla(0, 100%, 50%, 1), 
      3px -3px 0 hsla(0, 100%, 50%, 1), 
      2px -2px 0 hsla(0, 100%, 50%, 1), 
      1px -1px 0 hsla(0, 100%, 50%, 1),
      -4px 4px 0 hsla(180, 100%, 50%, 1), 
      -3px 3px 0 hsla(180, 100%, 50%, 1), 
      -2px 2px 0 hsla(180, 100%, 50%, 1), 
      -1px 1px 0 hsla(180, 100%, 50%, 1)
    ;
  }  
}
/* ======= */
.brand-marquee-area {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 40px;
  padding: 120px 0;
}
.brand-marquee-area .brand-marquee-wrapper.first-brand-marquee {
  animation: Scroll 32s linear infinite;
}
.brand-marquee-area .brand-marquee-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.brand-marquee-area .single-brand-marquee .brand-marquee-title {
  font-family: "Rajdhani", sans-serif;
  font-size: 68px;
  font-weight: 700;
  line-height: 78px;
  color: #23262D;
  white-space: nowrap;
}
.brand-marquee-area .single-brand-marquee:nth-child(even) .brand-marquee-title {
  -webkit-text-stroke: 2px #23262D;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.brand-marquee-area {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 40px;
  padding: 120px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-marquee-area {
    padding: 100px 0;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .brand-marquee-area {
    padding: 80px 0;
  }
}
@media (max-width: 767px) {
  .brand-marquee-area {
    gap: 20px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .brand-marquee-area {
    gap: 0;
  }
}
.brand-marquee-area .brand-marquee-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
@media only screen and (min-width: 0px) and (max-width: 576px) {
  .brand-marquee-area .brand-marquee-wrapper {
    gap: 26px;
  }
}
.brand-marquee-area .brand-marquee-wrapper.first-brand-marquee {
  animation: Scroll 32s linear infinite;
}
.brand-marquee-area .brand-marquee-wrapper.second-brand-marquee {
  animation: Scroll 32s linear infinite;
  animation-direction: reverse;
}
.brand-marquee-area .single-brand-marquee {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-shrink: 0;
}
.brand-marquee-area .single-brand-marquee:nth-child(even) .brand-marquee-title {
  -webkit-text-stroke: 2px #23262D;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}
.brand-marquee-area .single-brand-marquee .brand-marquee-title {
  font-family: "Rajdhani", sans-serif;
  font-size: 68px;
  font-weight: 700;
  line-height: 78px;
  color: #23262D;
  white-space: nowrap;
}
@media only screen and (min-width: 0px) and (max-width: 576px) {
  .brand-marquee-area .single-brand-marquee .brand-marquee-title {
    font-size: 32px !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .brand-marquee-area .single-brand-marquee .brand-marquee-title {
    font-size: 44px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-marquee-area .single-brand-marquee .brand-marquee-title {
    font-size: 54px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .brand-marquee-area .single-brand-marquee img {
    width: 50px;
  }
}
.brand-marquee-area.style-2 {
  padding: 0;
}
.brand-marquee-area .brand-marquee-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
@media only screen and (min-width: 0px) and (max-width: 576px) {
  .brand-marquee-area .brand-marquee-wrapper {
    gap: 26px;
  }
}
.brand-marquee-area .brand-marquee-wrapper.first-brand-marquee {
  animation: Scroll 32s linear infinite;
}

.brand-marquee-area .brand-marquee-wrapper.second-brand-marquee {
  animation: Scroll 32s linear infinite;
  animation-direction: reverse;
}




.custome-marquee {
  width: 100%;
  height:100px;
  overflow: hidden;
  position: relative;
}

.custome-marquee div {
    background-color:#29375F;
  display: block;
  width: 200%;
  height: 100px;

  position: absolute;
  overflow: hidden;

  animation: marquee 15s linear infinite;
}

.custome-marquee span {
  float: left;
    padding-top:10px;

  margin-left:50px;
  font-size: 4rem;
  font-weight:bolder;
  word-spacing:1rem;
  letter-spacing:0.8rem;
  text-stroke:1px;
  text-transform:uppercase;
  font-family: "Montserrat", sans-serif;
/*   -webkit-text-stroke: 2px #000; */
  color:white;
  font-style:italic;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
  text-align:center;

  
}

@keyframes marquee {
  0% { left: 0; }
  50% { left: -50%; }
  100% { left: 0; }
}


@media only screen and (max-width: 600px) {
  .section-title .title br {
    display: none;
  
  }
  .section-title .title  {
font-size: 23px;
  
  }
  .custome-marquee div {
    background-color:#29375F;
  display: block;
  width: 200%;
  height: 70px;
  }
  .custome-marquee span {
    font-size: 2rem;
  }
  .about-section-home-htwo {
    font-weight: 600;
    font-size: 1.500rem;
    line-height: 1.166;
    color: #000;
    letter-spacing: 0;
  }
   .experience-box {
    margin-top: 3rem;
  }
  .section-about-title {
    margin-bottom: 2rem;
  }
  .experience-number {
    display: inline-block;
    vertical-align: middle;
    font-size: 3.142rem;
    line-height: 1;
    font-weight: 600;
    color: #000;
}
.experience-info {
  display: inline-block;
  vertical-align: middle;
  margin: -1rem 0 0 0.8rem;
  font-size: 1.300rem;
  line-height: 1.181;
  color: #000;
}
}







.tc-testimonials-style1 .marq-slider {
  position: relative;
  overflow: hidden;

}
.tc-testimonials-style1 .marq-slider .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  position: relative;
}
.tc-testimonials-style1 .marq-slider .swiper-wrapper .swiper-slide {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
}
.tc-testimonials-style1 .marq-slider a {
  font-size: 150px;
  font-weight: 500;
  -webkit-text-stroke: 1px #000;
  color: transparent;
}
.tc-testimonials-style1 .marq-slider a:hover {
  color: #000;
}

@media screen and (max-width: 991px) {
  .tc-testimonials-style1 {
    padding: 50px 0;
  }
  .tc-testimonials-style1 .lg-icon {
    font-size: 120px;
  }
  .tc-testimonials-style1 .marq-slider a {
    font-size: 50px;
  }
}


/* CSS */
.banner-btn {
  align-items: center;
  background-image: linear-gradient(144deg,#370000, #396be1 50%,#230000);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 15px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 11px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.banner-btn:active,
.banner-btn:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .banner-btn {
    font-size: 20px;
    min-width: 196px;
  }
}

