



.footer {
    background-image: url(../images/section-bg/footer-five-bg.jpg);
    height: 100%;
    width: 100%;
  }
  
  
  .footer-widget-info {
    position: relative;
  }
  .footer-widget-info ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .footer-widget-info ul li {
    display: flex;
    align-items: start;
    gap: 17px;
    color: #23262D;
    padding: 0;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400;
  }
  .footer-widget-info ul li a {
    color: #23262D;
  }
  .footer-widget-info ul li p {
    margin-bottom: 0;
  }
  .footer-widget-info ul li i {
    color: #FF9205;
    font-weight: 600;
  }
  .footer-widget-info ul li:first-child {
    margin-top: 0;
  }
  .footer-widget-info .extra-info {
    margin-top: 5px;
  }
  .footer-widget-info .extra-info .time-slot {
    display: flex;
    align-items: center;
    gap: 17px;
    color: #23262D;
  }
  .footer-widget-info .extra-info .time-slot:before {
    content: "\f017";

    font-family: "Font Awesome 6 Pro";
    color: #FF9205;
    font-weight: 600;
  }
  .footer-widget-info .extra-info .time-slot p {
    margin-bottom: 0;
  }
  .footer-widget-info .wp-block-heading {
    color: #ffffff;
    /* font-size: 30px; */
  }
  .footer-widget-info .wp-block-heading::before {
    display: none;
  }
  .footer-widget-info .promotion-card {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .footer-widget-info .promotion-card .short-desc {
    font-size: 18px;
    line-height: 30px;
    font-weight: "Rubik", sans-serif;
  }
  .footer-widget-info .promotion-card h4 {
    color: #ffffff;
    font-family: "Rubik", sans-serif;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    margin: 0;
  }
  .footer-widget-info .promotion-card .secondary-btn {
    display: flex;
    align-items: center;
    gap: 18px;
  }
  .footer-widget-info .promotion-card .secondary-btn .icon {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    background: #D9A14C;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 18px;
  }
  .footer-widget-info .promotion-card .secondary-btn .icon i {
    color: #ffffff;
  }
  .footer-widget-info .promotion-card .secondary-btn .btn-text {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .footer-widget-info .promotion-card .secondary-btn .btn-text p {
    margin: 0;
    text-align: start;
    color: #ffffff;
    font-family: "Rubik", sans-serif;
    /* font-size: 14px; */
    line-height: 30px;
    font-weight: 400;
  }
  .footer-widget-info .promotion-card .secondary-btn .btn-text h4 {
    margin: 0;
    text-align: start;
    color: #ffffff;
    font-family: "Rajdhani", sans-serif;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
  }
  
  .footer {
    position: relative;
    z-index: 2;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .footer .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(35, 38, 45, 0.9) 0%, #23262d 100%);
  }
  .footer .divider {
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
    margin-top: 30px;
  }
  
  .footer .footer-sec {
    padding-top: 80px;
  }
  .footer .footer-sec hr {
    margin: 30px 0 0 0;
  }
  .footer .footer-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .footer .footer-bg img {
    width: 100%;
    height: 100%;
  }
  .footer .footer-sec .footer-widget ul {
    list-style: none;
  }
  .footer .footer-sec .footer-widget ul li {
    color: #ffffff;
  }
  .footer .footer-sec .footer-widget ul li a {
    color: #ffffff;
  }
  .footer .footer-sec .footer-widget ul li a:hover {
    color: #D9A14C;
  }
  .footer .footer-sec .footer-widget .textwidget p {
    color: #ffffff;
  }
  .footer .footer-sec .footer-widget .textwidget p strong {
    color: #ffffff;
  }
  .footer .footer-sec .footer-widget table caption, .footer .footer-sec .footer-widget table td, .footer .footer-sec .footer-widget table th {
    color: #ffffff;
  }
  .footer .footer-sec .footer-widget nav .wp-calendar-nav {
    color: #ffffff;
  }
  .footer .footer-sec .footer-widget nav .wp-calendar-nav a {
    color: #ffffff;
  }
  .footer .footer-sec .footer-widget nav .wp-calendar-nav-prev {
    color: #ffffff;
  }
  .footer .footer-sec .footer-widget nav .wp-calendar-nav-prev a {
    color: #ffffff;
  }
  .footer .footer-sec .footer-widget.widget_tag_cloud .tagcloud a {
    border: 1px solid #fff;
    padding: 5px 12px;
    border-radius: 4px;
    margin-bottom: 5px;
    display: inline-block;
    color: #ffffff;
  }
  .footer .footer-sec .footer-widget.widget_categories ul li, .footer .footer-sec .footer-widget.widget_archive ul li {
    text-align: right;
    display: inline-block;
    width: 100%;
  }
  .footer .footer-sec .footer-widget.widget_categories ul li a, .footer .footer-sec .footer-widget.widget_archive ul li a {
    float: left;
  }
  .footer .footer-sec .widget {
    background-color: transparent;
    padding: 0;
  }
  .footer .footer-sec .widget li article {
    color: #ffffff;
  }
  .footer .footer-sec .widget li article .wp-block-latest-comments__comment-meta {
    color: #ffffff !important;
  }
  .footer .footer-sec .widget li article .wp-block-latest-comments__comment-meta .wp-block-latest-comments__comment-date, .footer .footer-sec .widget li article .wp-block-latest-comments__comment-meta a, .footer .footer-sec .widget li article .wp-block-latest-comments__comment-meta p {
    color: #ffffff;
  }
  .footer .footer-sec .widget .wp-block-heading {
    color: #ffffff;
  }
  .footer .footer-sec .widget table caption, .footer .footer-sec .widget table td, .footer .footer-sec .widget table th, .footer .footer-sec .widget table caption {
    color: #ffffff;
  }
  .footer .footer-sec .widget ul li {
    color: #ffffff;
  }
  .footer .footer-sec .widget ul li a {
    color: #ffffff;
  }
  .footer .footer-sec .widget ul li ul {
    padding-left: 15px;
  }
  .footer .footer-sec .widget label,
  .footer .footer-sec .widget select {
    color: #ffffff;
  }
  .footer .footer-sec .widget .textwidget p {
    color: #ffffff;
  }
  .footer .footer-sec .widget .textwidget p strong {
    color: #ffffff;
  }
  .footer .footer-sec .widget nav .wp-calendar-nav {
    color: #ffffff;
  }
  .footer .footer-sec .widget nav .wp-calendar-nav a {
    color: #ffffff;
  }
  .footer .footer-sec .widget nav .wp-calendar-nav-prev {
    color: #ffffff;
  }
  .footer .footer-sec .widget nav .wp-calendar-nav-prev a {
    color: #ffffff;
  }
  .footer .footer-sec .widget.wp-block-archives ul li {
    text-align: right;
    display: inline-block;
    width: 100%;
  }
  .footer .footer-sec .widget.wp-block-archives ul li a {
    float: left;
  }
  
  .footer-widget-title {

    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
    position: relative;
    margin-bottom: 30px;
    font-weight: 700;
  }
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer-widget-title {
      margin-bottom: 20px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-widget-title {
      margin-bottom: 25px;
    }
  }
  
  .footer-widget {
    margin-bottom: 30px;
    position: relative;
  }
  @media (max-width: 991px) {
    .footer-widget {
      margin-bottom: 40px;
    }
  }
  .footer-widget ul {
    padding-left: 0;
  }
  .footer-widget .footer-logo {
    margin-bottom: 40px;
    margin-top: -25px;
  }
  .footer-widget.widget_boomdevs-latest-posts-footer {
    margin-top: -5px;
  }
  @media (max-width: 767px) {
    .footer-widget.zoom-instagram-widget {
      margin-bottom: 0;
      margin-top: -10px;
    }
  }
  @media (max-width: 767px) {
    .footer-widget.widget_boomdevs_contact_info_widget {
      margin-bottom: 18px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1399px) {
    .footer-widget .widget-instagram-feed {
      width: 170px;
    }
  }
  .footer-widget .footer-widget-info {
    position: relative;
  }
  .footer-widget .footer-widget-info p {
    color: #ffffff;
    margin-bottom: 40px;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
  }
  @media (min-width: 1400px) {
    .footer-widget .footer-widget-info p {
      padding-right: 40px;
    }
  }
  .footer-widget .footer-widget-info .footer-logo {
    margin-bottom: 40px;
    display: inline-block;
    margin-top: -25px;
    width: 139px;
    height: 40px;
  }
  .footer-widget .footer-widget-info .footer-logo a img {
    object-fit: cover;
  }
  .footer-widget .footer-widget-info .info-form-and-social-icon {
    display: flex;
    align-items: center;
    gap: 50px;
  }
  .footer-widget .footer-widget-info .info-form-and-social-icon .subscribe-form-widget {
    border-radius: 6px;
  }
  .footer-widget .footer-widget-info .info-form-and-social-icon .subscribe-form-widget .single-field input {
    height: 60px;
  }
  .footer-widget .footer-widget-info .info-form-and-social-icon .social-profile {
    margin-top: 0;
  }
  .footer-widget .footer-widget-info .social-profile {
    gap: 20px;
    margin-top: 30px;
  }
  
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer-widget .footer-widget-info .info-form-and-social-icon {
      flex-direction: column;
      align-items: start;
      gap: 30px;
    }
  }
  .footer-widget.widget_nav_menu ul {
    margin-top: -5px;
    padding-left: 0;
    margin-bottom: 0;
  }
  .footer-widget.widget_nav_menu li {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
  }
  .footer-widget.widget_nav_menu li:last-child {
    padding-top: 0;
    padding-bottom: 0;
  }
  .footer-widget.widget_nav_menu li a {
    color: #ffffff;
    text-transform: capitalize;
    font-size: 16px;
    transition: all 0.5s ease-out;
    position: relative;
    font-family: "Rubik", sans-serif;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-widget.widget_nav_menu li a {
      align-items: start;
    }
  }
  .footer-widget.widget_nav_menu li  {
    align-items: center;
    display: flex;

    font-size: 16px;
    color: #ffffff;
    gap: 10px;
  }
  .footer-widget.widget_nav_menu li a:hover {
    transition: all 0.5s ease-out;
    color: #D9A14C;
  }
  .footer-widget .footer-widget-info .extra-info {
    display: none;
  }
  .footer-widget .footer-widget-contact p {
    color: #ffffff;
    margin-bottom: 20px;
  }
  .footer-widget .footer-widget-contact .footer-contact ul {
    list-style: none;
    margin-bottom: 0;
  }
  .footer-widget .footer-widget-contact .footer-contact ul li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    line-height: 30px;
    gap: 10px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-widget .footer-widget-contact .footer-contact ul li {
      align-items: start;
    }
  }
  .footer-widget .footer-widget-contact .footer-contact ul li:last-child {
    margin-bottom: 0;
  }
  .footer-widget .footer-widget-contact .footer-contact ul li .contact-icon {
    border-radius: 50px;
    float: left;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  .footer-widget .footer-widget-contact .footer-contact ul li .contact-icon i {
    font-family: "Font Awesome 6 Pro";
    font-size: 16px;
    font-weight: 900;
    color: #D9A14C;
  }
  .footer-widget .footer-widget-contact .footer-contact ul li .contact-text {
    color: #ffffff;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    overflow: hidden;
    font-family: "Rubik", sans-serif;
  }
  .footer-widget .footer-widget-contact .footer-contact ul li .contact-text a {
    color: #ffffff;
  }
  .footer-widget .footer-widget-contact .footer-contact ul li .contact-text a:hover {
    color: #D9A14C;
  }
  .footer-widget .footer-widget-contact .footer-contact ul li .contact-text a:hover span {
    color: #D9A14C;
    transition: all 0.5s ease-out;
  }
  .footer-widget .footer-widget-contact .footer-contact ul li .contact-text span,
  .footer-widget .footer-widget-contact .footer-contact ul li .contact-text p,
  .footer-widget .footer-widget-contact .footer-contact ul li .contact-text a {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    font-family: "Rubik", sans-serif;
    transition: all 0.5s ease-out;
  }
  .footer-widget .footer-widget-contact .extra-info .extra-title {
    font-family: "Rajdhani", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    color: rgba(255, 255, 255, 0.7176470588);
    margin-bottom: 10px;
  }
  .footer-widget .widget_latest_post ul li {
    margin-bottom: 40px;
  }
  .footer-widget .widget_latest_post ul li .latest-post-thumb {
    width: 80px;
    height: 80px;
    border-radius: 0;
  }
  .footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-title {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Rubik", sans-serif;
  }
  .footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-title a {
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    position: relative;
    text-transform: capitalize;
    font-weight: 400;
    font-family: "Rubik", sans-serif;
    transition: all 0.5s ease-out;
  }
  .footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-title a:hover {
    color: #D9A14C;
    transition: all 0.5s ease-out;
  }
  .footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-meta {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 400;
    gap: 10px;
    color: #ffffff;
    font-family: "Rubik", sans-serif;
  }
  
  .footer-bottom-menu ul {
    list-style: none;
    display: flex;
    gap: 0 40px;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    padding-left: 0;
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer-bottom-menu ul {
      gap: 10px;
    }
  }
  @media (max-width: 991px) {
    .footer-bottom-menu ul {
      justify-content: flex-start;
    }
  }
  .footer-bottom-menu ul li a {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-transform: capitalize;
    color: #ffffff;
    font-family: "Rubik", sans-serif;
    transition: all 0.5s ease-out;
  }
  .footer-bottom-menu ul li a:hover {
    transition: all 0.5s ease-out;
    color: #D9A14C;
  }
  
  .footer-bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px 40px;
    flex-wrap: wrap-reverse;
  }
  .footer-bottom-wrapper .footer-widget {
    margin-bottom: 0;
  }
  
  .footer-bottom-menu-wrapper {
    display: flex;
    align-items: center;
    gap: 10px 40px;
    flex-wrap: wrap-reverse;
    flex-grow: 1;
    justify-content: space-between;
  }
  
  .footer-bottom-area {
    position: relative;
    padding: 30px 0;
  }
  .footer-bottom-area .copyright-text p {
    color: #ffffff;
    margin-bottom: 0;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
  }
  .footer-bottom-area .copyright-text p a {
    color: #ffffff;
    font-weight: 400;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    line-height: 30px;
  }
  .footer-bottom-area .copyright-text p a:hover {
    color: #D9A14C;
  }
  .footer-bottom-area .credit-text p {
    color: rgba(255, 255, 255, 0.7019607843);
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-family: "Rubik", sans-serif;
  }
  .footer-bottom-area .credit-text a {
    color: #D9A14C;
    font-family: "Rubik", sans-serif;
  }
  .footer-bottom-area .social-profile {
    gap: 25px;
  }
  
  .subscribe-form-wrapper p {
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    color: #ffffff;
    font-family: "Rubik", sans-serif;
    margin-bottom: 20px;
  }
  
  .subscribe-form-widget {
    position: relative;
  }
  .subscribe-form-widget .mc4wp-form-fields {
    display: flex;
    background-color: transparent;
    overflow: hidden;
  }
  @media only screen and (min-width: 0px) and (max-width: 576px) {
    .subscribe-form-widget .mc4wp-form-fields {
      flex-wrap: wrap;
    }
  }
  .subscribe-form-widget .mc4wp-form-255 {
    margin-top: 12px;
  }
  .subscribe-form-widget .mc4wp-form-255 .mc4wp-form-fields input[type=submit] {
    padding: 12px 20px;
    background-color: #D9A14C;
    color: #ffffff;
    transition: all 0.5s ease-out;
  }
  .subscribe-form-widget .mc4wp-form-255 .mc4wp-form-fields input[type=submit]:hover {
    background-color: #ffffff;
    color: #23262D;
    transition: all 0.5s ease-out;
  }
  .subscribe-form-widget .single-field {
    flex-grow: 1;
  }
  .subscribe-form-widget .single-field input {
    color: #ffffff;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
    width: 100%;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.7019607843);
    border-right: 0;
  }
  .subscribe-form-widget .single-field *::-webkit-input-placeholder {
    color: #ffffff;
  }
  .subscribe-form-widget .single-field *:-moz-placeholder {
    color: #ffffff;
    opacity: 1;
  }
  .subscribe-form-widget .single-field *::-moz-placeholder {
    color: #ffffff;
    opacity: 1;
  }
  .subscribe-form-widget .single-field *:-ms-input-placeholder {
    color: #ffffff;
  }
  .subscribe-form-widget .single-field *::-ms-input-placeholder {
    color: #ffffff;
  }
  .subscribe-form-widget .single-field *::placeholder {
    color: #ffffff;
  }
  .subscribe-form-widget .submit-btn {
    background-color: #D9A14C;
    color: #ffffff;
    cursor: pointer;
    padding: 15px 19px;
    display: flex;
    align-items: center;
    gap: 9px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.6px;
    white-space: nowrap;
  }
  .subscribe-form-widget .submit-btn span {
    display: none;
  }
  .subscribe-form-widget .submit-btn:hover {
    color: #23262D;
    background-color: #ffffff;
  }
  .subscribe-form-widget .mc4wp-response {
    position: relative;
    top: 15px;
  }
  .subscribe-form-widget .mc4wp-response .mc4wp-alert.mc4wp-error p, .subscribe-form-widget .mc4wp-response .mc4wp-alert.mc4wp-success p, .subscribe-form-widget .mc4wp-response .mc4wp-alert.mc4wp-notice p {
    color: #fff;
    position: relative;
  }
  
  .social-profile {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .social-profile a {
    color: #ffffff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: rgba(244, 244, 244, 0.1);
    transition: all 0.5s ease-out;
  }
  .social-profile a:hover {
    transition: all 0.5s ease-out;
    color: #FF9205;
  }
  

  
  .footer .widget-menu-wrapper {
    padding-left: 50px;
    padding-right: 30px;
  }
  @media (max-width: 991px) {
    .footer .widget-menu-wrapper {
      padding-left: 0;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer .widget-menu-wrapper {
      padding-left: 12px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1399px) {
    .footer .widget-menu-wrapper {
      padding-right: 0;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .footer .widget-menu-wrapper {
      padding-right: 0;
    }
  }
  .footer.style-2 .footer-widget-info .extra-info {
    margin-top: 20px;
  }
  .footer.style-2 .footer-widget-info .extra-info .time-slot {
    margin-bottom: 0;
  }
  .footer.style-2 .footer-widget-info p,
  .footer.style-2 .footer-widget.widget_nav_menu li a,
  .footer.style-2 .footer-bottom-menu ul li a,
  .footer.style-2 .footer-bottom-area .copyright-text p {
    color: #ffffff;
  }
  .footer.style-2 .footer-widget.widget_nav_menu li a:hover,
  .footer.style-2 .footer-bottom-menu ul li a:hover {
    color: #D9A14C;
  }
  .footer.style-2 .footer-bottom-area .copyright-text p a {
    color: #8f939b;
    font-weight: 700;
  }
  .footer.style-2 .footer-bottom-area .copyright-text p a:hover {
    color: #D9A14C;
  }
  .footer.style-2 .footer-bottom-menu {
    display: flex;
    flex-grow: 1;
    justify-content: end;
  }
  @media (max-width: 991px) {
    .footer.style-2 .footer-bottom-menu {
      justify-content: start;
    }
  }
  @media (max-width: 991px) {
    .footer.style-2 .copyright-text {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .footer.style-2 .footer-widget .social-profile a {
    color: unset;
    background-color: transparent;
  }
  .footer.style-2 .footer-widget .social-profile a i {
    color: #ffffff;
  }
  .footer.style-2 .footer-widget .social-profile a:hover i {
    color: #D9A14C;
  }
  .footer.style-2 .footer-widget.widget_nav_menu li a:before {
    display: none;
  }
  .footer.style-2 .footer-widget .footer-widget-contact p {
    margin-bottom: 0;
  }
  .footer.style-2 .footer-widget .footer-widget-contact .footer-contact ul li {
    align-items: start;
  }
  .footer.style-2 .footer-widget .footer-widget-contact .footer-contact ul li .contact-text a, .footer.style-2 .footer-widget .footer-widget-contact .footer-contact ul li .contact-text span {
    color: rgba(255, 255, 255, 0.7019607843);
  }
  .footer .subscribe-form-widget.style-2 .mc4wp-form-fields {
    border-color: rgb(199, 199, 225);
    display: block;
  }
  .footer .subscribe-form-widget.style-2 .submit-btn {
    color: #23262D;
    width: auto;
    margin-top: 20px;
    padding: 12px 19px;
  }
  .footer .subscribe-form-widget.style-2 .submit-btn i {
    display: none;
  }
  .footer .subscribe-form-widget.style-2 .submit-btn span {
    display: block;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 1.28px;
    font-family: "Rajdhani", sans-serif;
  }
  .footer .subscribe-form-widget.style-2 .single-field input {
    height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .footer.style-3 .footer-widget .footer-widget-info p {
    color: rgba(255, 255, 255, 0.7019607843);
  }
  .footer.style-3 .footer-widget.widget_nav_menu li a {
    color: #ffffff;
  }
  .footer.style-3 .footer-widget.widget_nav_menu li a:before {
    content: "\f00c";
    height: 14px;
    width: 14px;
    background-color: #D9A14C;
    border-radius: 2px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #23262D;
    font-weight: 700;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer.style-3 .footer-widget.widget_nav_menu li a:before {
      margin-top: 7px;
    }
  }
  .footer.style-3 .footer-widget .footer-widget-info p {
    padding-right: 0;
  }
  .footer.style-3 .footer-widget .social-profile a:hover {
    color: #ffffff;
    background-color: #D9A14C;
  }
  .footer.style-3 .footer-widget .footer-widget-contact p {
    color: rgba(255, 255, 255, 0.7019607843);
  }
  .footer.style-3 .footer-widget .footer-widget-contact .extra-info .time-slot p {
    margin-bottom: 0;
  }
  .footer .footer-top-area {
    margin-bottom: 80px;
  }
  
  .footer.style-4 .subscribe-footer-widget {
    text-align: center;
    position: relative;
  }
  .footer.style-4 .footer-top-widget-title {
    position: relative;
    padding: 0 70px;
    margin-bottom: 40px;
    color: #ffffff;
  }
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer.style-4 .footer-top-widget-title {
      padding: 0;
    }
  }
  .footer.style-4 .footer-top-widget-title h2, .footer.style-4 .footer-top-widget-title h3 {
    color: #ffffff;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .footer.style-4 .widget-menu-wrapper {
      padding-left: 0;
    }
  }
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer.style-4 .footer-widget .footer-logo {
      margin-bottom: 0;
    }
  }
  .footer.style-4 .footer-widget .footer-widget-info p {
    margin-bottom: 50px;
  }
  @media (max-width: 991px) {
    .footer.style-4 .footer-widget .widget_latest_post ul li {
      margin-bottom: 20px;
    }
  }
  .footer.style-4 .footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-meta,
  .footer.style-4 .footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-title {
    color: rgba(255, 255, 255, 0.7019607843);
  }
  .footer.style-4 .footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-meta a,
  .footer.style-4 .footer-widget .widget_latest_post ul li .latest-post-desc .latest-post-title a {
    color: rgba(255, 255, 255, 0.7019607843);
  }
  .footer.style-4 .footer-widget.widget_nav_menu li a:before {
    display: none;
  }
  
  .footer.style-5 .footer-top-area {
    position: relative;
  }
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer.style-5 .footer-top-area {
      margin-bottom: 30px;
    }
  }
  .footer.style-5 .footer-top-area .footer-widget-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border: 1px solid #E3E3E3;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer.style-5 .footer-top-area .footer-widget-info {
      flex-direction: column;
      gap: 30px;
    }
  }
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer.style-5 .footer-top-area .footer-widget-info {
      flex-direction: column;
      gap: 30px;
      align-items: start;
    }
  }
  .footer.style-5 .footer-top-area .footer-widget-info .footer-widget-contact .footer-contact ul {
    display: flex;
    align-items: center;
    gap: 45px;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
  }
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    .footer.style-5 .footer-top-area .footer-widget-info .footer-widget-contact .footer-contact ul {
      flex-direction: column;
      align-items: start;
      gap: 20px;
    }
  }
  .footer.style-5 .footer-top-area .footer-widget-info .footer-widget-contact .footer-contact ul li {
    display: flex;
    gap: 10px;
    color: #ffffff;
  }
  .footer.style-5 .footer-top-area .footer-widget-info .footer-widget-contact .footer-contact ul li p {
    margin-bottom: 0;
  }
  .footer.style-5 .footer-top-area .footer-widget-info .footer-widget-contact .footer-contact ul li i {
    color: #D9A14C;
  }
  .footer.style-5 .footer-top-area .footer-widget-info .footer-widget-contact .footer-contact ul li a {
    color: #ffffff;
  }
  .footer.style-5 .footer-widget .social-profile a:hover {
    color: #ffffff;
    background-color: #D9A14C;
  }
  .footer.style-5 .footer-widget.widget_nav_menu li a:before {
    display: none;
  }